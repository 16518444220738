import React from "react";
import styles from "../../../styles/General.module.css";
import { Grid } from "@mui/material";

const Benefits = ({ data, video, cover }) => {
  const MyVideo = ({ videoUrl, image }) => {
    return (
      <div style={{ position: 'relative', cursor: 'pointer', margin: '20px 0' }} onClick={() => window.open(videoUrl, '_blank')}>
        <img src={image} alt="Video Thumbnail" style={{ width: '100%', height: 'auto', margin: '10px 0 40px 0' }} />
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '50%',
          padding: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <span style={{ fontSize: '24px', color: 'white' }}>▶</span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.servicePageBenefits}>
      <div className={styles.homeCaseStudy}>
        <h1 style={{ color: 'white' }}>Benefits</h1>
      </div>
      <Grid container>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <ul className={styles.benefitsList} style={{ listStyle: 'none', padding: 0 }}>
            {data?.map((rec, index) => (
              <li key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                <span style={{
                  display: 'inline-block',
                  width: '5px',
                  height: '5px',
                  borderRadius: '50%',
                  backgroundColor: 'white',
                  marginRight: '8px'
                }}></span>
                <p style={{ margin: 0 }}>{rec.title}</p>
              </li>
            ))}
          </ul>
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <MyVideo
            videoUrl={`https://www.youtube.com/watch?v=${video}`}
            image={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/images/${cover ?? null}`}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Benefits;
