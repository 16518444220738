import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/General.module.css";


const CaseStudy = ({ data }) => {
  return (
    <div style={{ padding: "0px" }}>
        <div className={styles.homeCaseStudy}>
        <h1>Case Study</h1>
      </div>
      <div style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}>
        {data.map((item) => (
          <Link
            key={item.id}
            to={`/case-study/${item.id}`} // Navigate to specific case study
            style={{
              textDecoration: "none", // Remove underline
              color: "inherit", // Inherit text color
              margin: "20px",
            }}
          >
            <div
              style={{
                background: "#183154",
                borderRadius: "10px",
                width: "300px",
                minHeight: "400px",
                maxHeight: "400px", 
                overflow: "hidden",
                // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ height: "50%", width: "100%", overflow: "hidden" }}>
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/images/${item.image}`}
                  alt={item.title}
                  style={{
                    // paddingBottom:'200px',
                    width: "100%",
                    height: "200px",
                    objectFit: "cover", // Use contain to maintain aspect ratio
                  }}
                />
              </div>
              <div style={{
                padding: "10px",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "50%", // Ensure description section takes the remaining space
              }}>
                <h2 style={{ margin: "0 0 10px 0", color: "#fff" }}>{item.title}</h2>
                <p style={{ color: "#fff", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical", flexGrow: 1 }}>
                  {item.description}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CaseStudy;
