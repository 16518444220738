import React from "react";
import styles from "../../../styles/General.module.css";
import CustomCarousel from "../../../components/carousel";
import IMG1 from "../../../assets/images/service1.png";
import IMG2 from "../../../assets/images/service2.png";
import IMG3 from "../../../assets/images/service3.png";
import IMG4 from "../../../assets/images/service4.png";
import IMG5 from "../../../assets/images/service5.png";
import useFetch from "../../../customHooks/useFetch";

const Services = () => {
  const { data, error, loading } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/data/services`,
    {}
  );

  // const data = [
  //   {
  //     id: 1,
  //     title: "STEAM integration",
  //     description:
  //       "This service would involve creating a comprehensive plan for social media marketing that aligns with the school's goals and objectives. It could include a description calendar, target audience identification, and platform selection.",
  //     imgSrc: IMG1,
  //   },
  //   {
  //     id: 2,
  //     title: "Software Selection and Implementation",
  //     description:
  //       "Our Software Selection and Implementation service is designed to help schools identify the best software solutions to meet their specific needs and then implement them effectively.",
  //     imgSrc: IMG2,
  //   },
  //   {
  //     id: 3,
  //     title: "Social Media strategy development",
  //     description:
  //       "This service would involve creating a comprehensive plan for social media marketing that aligns with the school's goals and objectives. It could include a description calendar, target audience identification, and platform selection.",
  //     imgSrc: IMG3,
  //   },
  //   {
  //     id: 4,
  //     title: "Digital Learning Platform Development",
  //     description:
  //       "Our Digital Learning Platform Development service helps schools create a comprehensive digital learning platform that incorporates STEAM and sustainability elements. We'll work with your school to design and develop a customized platform that meets your specific needs and integrates with your existing software solutions.",

  //     imgSrc: IMG4,
  //   },
  //   {
  //     id: 5,
  //     title: "Sustainbale Campus Design",
  //     description:
  //       "Our Digital Learning Platform Development service helps schools create a comprehensive digital learning platform that incorporates STEAM and sustainability elements. We'll work with your school to design and develop a customized platform that meets your specific needs and integrates with your existing software solutions.",
  //     imgSrc: IMG5,
  //   },
  // ];
  return (
    <div className={styles.homeServices} id="our-services">
      <h1>
        Our Services <span></span>
      </h1>
      {/* <p>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam.
      </p> */}

      <CustomCarousel url="/service" slidesToShow={3} data={data} />
    </div>
  );
};

export default Services;
