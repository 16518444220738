import React, { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useMediaQuery } from "@mui/material";

const CustomizedTimeline = ({ data }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [expandedItems, setExpandedItems] = useState(data.map(() => false));

  const handleToggle = (index) => {
    setExpandedItems((prev) => {
      const newExpanded = [...prev];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  return (
    <>
      {isMobile ? (
        <div>
          {data.map((item, index) => (
            <div
              key={index}
              style={{
                margin: "16px",
                padding: "25px",
                border: "1px solid #ccc",
                borderRadius: "8px",
              }}
            >
              <Typography variant="h6" sx={{ color: "#003366" }}>
                {item.title}
              </Typography>
              <Typography
                sx={{
                  color: "text.secondary",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: expandedItems[index] ? "none" : 3, 
                }}
              >
                {item.description}
              </Typography>
              <Button onClick={() => handleToggle(index)} sx={{ mt: 1 }}>
                {expandedItems[index] ? "Show Less" : "Read More"}
              </Button>
            </div>
          ))}
        </div>
      ) : (
        <Timeline position="alternate">
          {data.map((item, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  sx={{
                    bgcolor: "#003366",
                    width: 24,
                    height: 24,
                    borderRadius: "50%",
                  }}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <div
                  // style={{
                  //   backgroundColor: "rgba(0, 51, 102, 0.1)",
                  //   padding: "8px",
                  //   borderRadius: "4px",
                  // }}
                >
                  <Typography
                    variant="h6"
                    component="span"
                    sx={{ color: "#003366", fontSize: "1.25rem" }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      mt: 0.5,
                      overflow: "visible", 
                      display: "block", 
                    }}
                  >
                    {item.description}
                  </Typography>
                  {/* No button on larger screens */}
                </div>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
    </>
  );
};

export default CustomizedTimeline;
