import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from "../../styles/General.module.css";
import { useMediaQuery } from "@mui/material";

import RightArrow from "../../assets/icons/rightArrow.png";
import LeftArrow from "../../assets/icons/leftArrow.png";
import { useRef } from "react";
import { Link } from "react-router-dom";

const CustomCarousel = (props) => {
  const mobile = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:800px)");
  const sliderRef = useRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      props?.data?.length < 2
        ? 1
        : props?.data?.length < 3
        ? 2
        : props?.slidesToShow
        ? props?.slidesToShow
        : 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: props.slidesToShow ?? 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const Card = ({ id, title, image, description, isCase }) => {
    return isCase ? (
      <div
        style={{
          background: "#183154",
          borderRadius: "40px ",
          width: "max(calc(353px / 2), calc((5.8vw * 353) / 100))",
          height: "max(calc(215px / 2), calc((5.8vw * 215) / 100))",
          margin: "auto",
          cursor: "pointer",
        }}
      >
        <Link to={`${props.url}/${id}`}>
          <img src={image} width={"100%"} />
        </Link>
      </div>
    ) : (
      <div className={styles.carouselCard}>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    );
  };

  return (
    <div
      style={{
        position: "relative",
        marginTop: "max(calc(100px / 2), calc((5.8vw * 100) / 100))",
      }}
    >
      <span
        className={styles.arrowLeft}
        onClick={() => sliderRef?.current.slickPrev()}
      >
        <img src={LeftArrow} />
      </span>

      <Slider
        ref={sliderRef}
        {...settings}
        arrows={false}
        style={
          {
            // paddingInline: "max(calc(100px / 2), calc((5.8vw * 100) / 100))",
          }
        }
      >
        {props?.data?.map((rec) =>
          props.card ? (
            <Card
              id={rec.id}
              title={rec.title}
              description={rec.description}
              image={`${
                process.env.REACT_APP_API_BASE_URL_ATTACHMENTS
              }/images/${rec.image ?? null}`}
              isCase={props.case}
            />
          ) : (
            <Link
              to={`${props.url}/${rec.id}`}
              className={`${styles.slide} ${
                props.BigSlide ? styles.bigSlide : null
              }`}
            >
              <img
                // src={rec.imgSrc}
                src={`${
                  process.env.REACT_APP_API_BASE_URL_ATTACHMENTS
                }/images/${rec.image ?? null}`}
              />
              <h1>{rec.title}</h1>
              <p>{rec.description}</p>
            </Link>
          )
        )}
      </Slider>
      <span
        className={styles.arrowRight}
        onClick={() => sliderRef?.current.slickNext()}
      >
        <img src={RightArrow} />
      </span>
    </div>
  );
};

export default CustomCarousel;
