import React from "react";
import styles from "../../../styles/General.module.css";
import { Grid } from "@mui/material";
import CustomCarousel from "../../../components/carousel";

import Img1 from "../../../assets/images/caseStudy1.png";
import Img2 from "../../../assets/images/caseStudy2.png";
import useFetch from "../../../customHooks/useFetch";

const CaseStudy = () => {
  const { data, error, loading } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/data/case-studies`,
    { limit: 6 }
  );

  // const data = [
  //   {
  //     id: 1,
  //     imgSrc: Img1,
  //   },
  //   {
  //     id: 2,
  //     imgSrc: Img2,
  //   },

  //   {
  //     id: 3,
  //     imgSrc: Img1,
  //   },
  //   {
  //     id: 4,
  //     imgSrc: Img2,
  //   },
  // ];
  return (
    <div className={styles.homeCaseStudy} id="case-studies">
      <h1>
        Case Study <span></span>
      </h1>
      {/* <p>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam.
      </p> */}

      <CustomCarousel url="/case-study" slidesToShow={2} data={data} BigSlide />
    </div>
  );
};

export default CaseStudy;
