import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/logoo.png";
import styles from "../../styles/Layout.module.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { useState } from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Footer = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setError(false);
  };

  const submit = () => {
    if (!email) return;
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/user/subscribe`, { email })
      .then((res) => {
        setOpen(true);
        setEmail("");
      })
      .catch((error) => {
        if (error.response.data == 1062) {
          setError(true);
          setEmail("");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mobile = useMediaQuery("(max-width:600px)");

  const navItems = [
    { id: 1, name: "Home", route: "/" },
    { id: 2, name: "About us", route: "/about" },
    { id: 3, name: "Contact Us", route: "/contact" },
  ];

  const footerItems = [
    { id: 1, name: "Services", route: "/#services" },
    {
      id: 2,
      name: "Creative Design",
      route: "/services/marketing-consultancy",
    },
    {
      id: 3,
      name: "Business Development",
      route: "/services/business-development",
    },
    {
      id: 4,
      name: "Marketing Consultancy",
      route: "/services/marketing-consultancy",
    },
    {
      id: 5,
      name: "Mobile App & Web Development",
      route: "/services/mobile-application-and-website-development",
    },
  ];

  return (
    <Grid
      container
      style={{
        // paddingTop: 50,
        // paddingRight: 0,
        // paddingBottom: 100,
        // paddingLeft: mobile ? 35 : 70,
        paddingBlock: 20,
        background: "#000000",
      }}
    >
      <Grid
        item
        xl={4}
        lg={4}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* {navItems.map((item, index) => (
          <Button
            className={styles.footerLink}
            key={index}
            sx={{ color: "#fff", justifyContent: "flex-start" }}
            component={Link}
            to={item.route}
          >
            {item.name}
          </Button>
        ))} */}
        <div className="center-aligned-column">
          <img src={Logo} width="250" />
          <p className={styles.footerText}>
            BRKS Education is a registered trademark and the content, including
            but not limited to text, images, logos, and designs, displayed on
            our website is protected by intellectual property laws and may not
            be used without our explicit permission.
          </p>
        </div>
      </Grid>
      <Grid
        item
        xl={8}
        lg={8}
        md={12}
        sm={12}
        xs={12}
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        // sx={{
        //   display: "flex",
        //   flexDirection: "column",
        //   alignItems: "center",
        //   alignContent: "center",
        // }}
      >
        <Typography className={styles.footerTitle}>Our Newsletter</Typography>
        <div
          style={{
            display: "flex",
            width: "fit-content",
            alignItems: "center",
            position: "relative",
            // marginLeft: 50,
          }}
        >
          <input
            value={email}
            className={styles.footerInput}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          {loading ? (
            <div className="loader"></div>
          ) : (
            <Button
              onClick={submit}
              className={styles.subscribeBtn}
              variant="contained"
              sx={
                {
                  // maxWidth: 235,
                  // marginLeft: "auto",
                  // marginTop: 4,
                  // height: 62,
                }
              }
            >
              SUBSCRIBE
            </Button>
          )}
        </div>
      </Grid>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Successfully subscribed
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Already Subscribed
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default Footer;
