import React from "react";
import styles from "../../../styles/General.module.css";
import Arrow from "../../../assets/images/vectorArrow.png";
import Card from 'react-bootstrap/Card';

const Title = ({ title, points, packages }) => {
  // Ensure packages is defined and an array
  const packagesList = Array.isArray(packages) ? packages : [];

  return (
    <>
      <div className={styles.homeCaseStudy}>
        <h1>
          {/* <span>1 </span> */}
          {title}
        </h1>
        <div 
          className={styles.servicePageTitleImages} 
          style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            flexWrap: 'wrap', 
            gap: '20px', 
            marginBottom: '20px' 
          }}
        >
          {packagesList.map((p) => (
           <Card 
           key={p.id} 
           style={{ 
             width: '100%', 
             maxWidth: '20rem', 
             border: 'none', 
             backgroundColor: '#003366', 
             borderRadius: '20px',
             display: 'flex',
             flexDirection: 'column',
             alignItems: 'center', // Center align items
             padding: '0', // No extra padding
           }}
         >
           <div style={{ 
             padding: '10px', // Adjust padding around the image
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             width: '100%',
             height: '200px', // Increased height for the image container
           }}>
             <Card.Img 
               variant="top" 
               src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/images/${p.image ?? null}`} 
               style={{ 
                 borderRadius: '15px', 
                 height: '100%', // Make the image fill the height of the container
                 width: '100%', // Make the image fill the width of the container
                 objectFit: 'cover' // Cover the area while maintaining aspect ratio
               }} 
             />
           </div>
           <Card.Body style={{ textAlign: 'left', padding: '10px' }}>
             <Card.Title style={{ color: 'white' }}>{p.title}</Card.Title>
           </Card.Body>
         </Card>
         
          
          
          
          ))}
        </div>
      </div>

      {/* <div className={styles.servicePagePoints}>
        {points?.length > 0 && (
          <ul>
            {points?.slice(0, points.length / 2).map((point, i) => (
              <li key={i}>
                <span>{point?.title}: </span>
                {point?.description}
              </li>
            ))}
            <span>
              <img src={Arrow} alt="Arrow" />
            </span>
          </ul>
        )}
        {points?.length > 1 && (
          <ul>
            {points?.slice(points.length / 2).map((point, i) => (
              <li key={i}>
                <span>{point?.title}: </span>
                {point?.description}
              </li>
            ))}
            <span>
              <img src={Arrow} alt="Arrow" />
            </span>
          </ul>
        )}
      </div> */}
    </>
  );
};

export default Title;
