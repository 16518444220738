import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import useFetch from "../../customHooks/useFetch";
import styles from "../../styles/General.module.css";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const CaseStudy = () => {
  const { pathname } = useLocation();

  const { data, error } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/data/case-study`,
    { id: pathname.split("case-study/")[1] }
  );
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = () => {
    if (!email) return;
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/user/attachment`, {
        email,
        id: pathname.split("case-study/")[1],
      })
      .then((res) => {
        setOpen(false);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.caseStudyPage}>
      {/* <img
        src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/images/${
          data?.image ?? null
        }`}
      /> */}
      <h1>
        {data?.fullTitle} <span></span>
      </h1>

      <p>{data?.description}</p>
      <i>{data?.author}</i>
      <a
        target="_blank"
        // href={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/files/${data?.attachment}`}
        onClick={handleClickOpen}
      >
        Download
      </a>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Get Attachment link By email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please type your email and click send
          </DialogContentText>
          <TextField
            disabled={loading}
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </DialogContent>
        <DialogActions>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSend}>Send</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CaseStudy;
