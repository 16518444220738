import React from "react";
import Title from "./sections/Title";
import Methodology from "./sections/Methodology";
import styles from "../../styles/General.module.css";
import Benefits from "./sections/Benefits";
import CaseStudy from "./sections/CaseStudy";
import useFetch from "../../customHooks/useFetch";
import { useLocation } from "react-router-dom";
const Service = () => {
  const { pathname } = useLocation();

  const { data, error, loading } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/data/service`,
    { id: pathname.split("service/")[1] }
  );

  return (
    <div className={styles.servicePage}>
      <Title
        title={data?.title}
        points={data?.Points}
        packages={data?.Packages}
      />

      {data?.Methodologies?.length > 0 && (
        <Methodology data={data?.Methodologies} />
      )}
      {data?.Benefits?.length > 0 && (
        <Benefits
          data={data?.Benefits}
          video={data?.video}
          cover={data?.videoCover}
        />
      )}
      {data?.CaseStudies?.length > 0 && <CaseStudy data={data?.CaseStudies} />}
    </div>
  );
};

export default Service;
