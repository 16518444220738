import React from "react";
import IMG from "../../assets/images/contact.png";
import IMGBottom from "../../assets/images/contactBottom.png";
import Logo from "../../assets/logo/logoBig.png";
import styles from "../../styles/General.module.css";
import { Grid } from "@mui/material";
import ContactSection from "./ContactSection";
import { useEffect } from "react";
const Contact = () => {
  const customStyle = {
    backgroundImage: `url(${IMG}) `,
    width: "100vw",
    height: "90vh",
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "inset 0 0 0 2000px rgba(255, 253, 119, 0.5)",
  };

  const bottomStyle = {
    backgroundImage: `url(${IMGBottom}) `,
    width: "100vw",
    height: "110vh",
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    boxShadow: "inset 0 0 0 2000px rgba(255, 253, 119, 0.5)",
  };

  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }, []);

  return (
    <div className={styles.contactPage}>
      <div style={customStyle} className={styles.contactPageHero}>
        <img src={Logo} />
        <div>
          <h1>Join the Ranks of the Elite: Elevate Your Education with Us.</h1>
          <h2>
            Attract Top Talent and Earn a Stellar Reputation - Guaranteed.
          </h2>
        </div>
        <a href="https://calendly.com/brkseducation/30min" target="_blank">
          Contact Us 📞
        </a>
        {/* <img src={IMG} style={{ visibility: "hidden", width: "100vw" }} /> */}
      </div>

      <Grid container>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          spacing={0}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div className={styles.contactPhilo}>
            <h1>Our philosophy </h1>
            <p>
              Our philosophy is grounded in the belief that
              <span>
                {" "}
                every school should have access to high-quality marketing{" "}
              </span>
              and branding services that enhance their reputation and education
              standards <span>without breaking the bank</span>.
            </p>
            <p>
              <span> We're not </span>your typical{" "}
              <span>big consulting firm</span> - we offer customised services
              and <span>personal attention to every client,</span> ensuring that
              they receive the support and resources they need to succeed.
            </p>
          </div>
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{}}
          spacing={0}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/brkseducation/30min?hide_gdpr_banner=1"
            style={{
              minWidth: 320,
              height: "120vh",
              width: "100%",
            }}
          ></div>
        </Grid>
      </Grid>

      <div style={bottomStyle} className={styles.contactPageBottom}>
        <h1>👋🏻 What we offer</h1>
        <p>
          🚀Unlock the Full Potential of Your School with Our Comprehensive
          Marketing and Branding Services:
        </p>{" "}
        <p>
          ✅ Build a Positive Reputation: Showcase Your School's Unique
          Strengths and Values.{" "}
        </p>{" "}
        <p>
          ✅ Expand Your Reach: Increase Your Visibility and Attract Top Talent.
        </p>{" "}
        <p>
          ✅ Enhance Engagement: Connect with Your Community through Social
          Media and Outreach.
        </p>{" "}
        <p>
          ✅ Optimize Your Impact: Maximize Your Resources and Achieve Your
          Goals.
        </p>
        <a href="https://calendly.com/brkseducation/30min" target="_blank">
          Contact Us 📞
        </a>
      </div>
      <ContactSection />
    </div>
  );
};

export default Contact;
