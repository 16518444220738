import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styles from "../../styles/General.module.css";
import Snackbar from "@mui/material/Snackbar";

import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ContactSection = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const mainStyle = {
    // backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/Pages/${image})`,
    // backgroundRepeat: "no-repeat, repeat",
    // backgroundPosition: "center",
    // backgroundSize: "cover",
    minHeight: "100vh",
    marginBottom: -100,
  };

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/user/contact`, data)
      .then((res) => {
        setOpen(true);
        reset();
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={mainStyle} className={styles.contactWrapper} id="send">
      <div className={`${styles.row} ${styles.contactSection}`}>
        <div style={{}}>
          {/* <h1>CONTACT US</h1> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label></label>
              <input
                placeholder="FULL NAME"
                className={styles.contactInput}
                style={{ borderColor: errors.fullName && "red" }}
                {...register("fullName", { required: true })}
              />
              {errors.fullName && (
                <span style={{ fontSize: 12, color: "red", marginLeft: 10 }}>
                  This field is required
                </span>
              )}
            </div>
            <div>
              <label></label>
              <input
                placeholder="EMAIL ADDRESS"
                className={styles.contactInput}
                style={{ borderColor: errors.email && "red" }}
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span style={{ fontSize: 12, color: "red", marginLeft: 10 }}>
                  This field is required
                </span>
              )}
            </div>
            <div>
              <label></label>
              <textarea
                rows="4"
                cols="50"
                placeholder="MESSAGE"
                className={styles.contactTextArea}
                style={{ borderColor: errors.message && "red" }}
                {...register("message", { required: true })}
              ></textarea>
              {errors.message && (
                <span style={{ fontSize: 12, color: "red", marginLeft: 10 }}>
                  This field is required
                </span>
              )}
            </div>

            {loading ? (
              <div className="loader"></div>
            ) : (
              <input className={styles.contactBtn} type="submit" value="SEND" />
            )}
          </form>
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Message sent
            </Alert>
          </Snackbar>
        </div>
        {/* <div style={{}}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.3400416148797!2d35.64833321498636!3d34.086428380596715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f436326739a21%3A0x4fc009d7e2025385!2sGondola%20Marine%20Resort!5e0!3m2!1sen!2slb!4v1679399104818!5m2!1sen!2slb"
            width={"100%"}
            height={"100%"}
            style={{ border: 0 }}
            loading="lazy"
            referrerpolicy={"no-referrer-when-downgrade"}
          ></iframe>
        </div> */}
      </div>
    </div>
  );
};

export default ContactSection;
