import React from "react";

import Img1 from "../../../assets/images/kids.png";
import Img3 from "../../../assets/images/signup.png";
import Img2 from "../../../assets/images/teacher.png";
import { Grid } from "@mui/material";
import styles from "../../../styles/General.module.css";

const Steam = ({ data }) => {
  const {
    title1,

    image2,
    paragraph2,

    image3,
    paragraph3,

    image4,
    paragraph4,
  } = data;
  return (
    <div className={styles.steam} id="curriculum">
      <div>
        <h1>{title1}</h1>

        <Grid container>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            spacing={0}
            container
            // direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div
              dangerouslySetInnerHTML={{ __html: paragraph2 ?? "" }}
              className={styles.steamTextBlack}
            />
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            spacing={0}
            container
            // direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <img
              // src={Img1}
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pages/${
                image2 ?? null
              }`}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            spacing={0}
            container
            // direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <img
              // src={Img2}
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pages/${
                image3 ?? null
              }`}
            />
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            spacing={0}
            container
            // direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div
              dangerouslySetInnerHTML={{ __html: paragraph3 ?? "" }}
              className={styles.steamTextWhite}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            spacing={0}
            container
            // direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div
              dangerouslySetInnerHTML={{ __html: paragraph4 ?? "" }}
              className={styles.steamTextWhite}
            />
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            spacing={0}
            container
            // direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <img
              // src={Img3}
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pages/${
                image4 ?? null
              }`}
            />
          </Grid>
        </Grid>
      </div>
      <span>we focus on stem and storytelling</span>
    </div>
  );
};

export default Steam;
