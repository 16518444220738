import { Grid } from "@mui/material";
import React from "react";
import Img from "../../../assets/images/home-hero-img.png";
import styles from "../../../styles/General.module.css";

const Hero = ({ data }) => {
  const { paragraph1, image1, link1 } = data;
  const text =
    "<h1>Transforming <b>Education</b>  for the Digital Age.</h1> <p> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam. </p>";
  return (
    <Grid
      container
      style={{ maxHeight: 775, backgroundColor: "rgba(254, 206, 48, 1)" }}
    >
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{}}
        container
        spacing={0}
        direction="column"
        alignItems="left"
        alignContent="center"
        justifyContent="center"
        // style={{ minHeight: "100vh" }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: paragraph1 ?? "" }}
          className={styles.homeHeroText}
        />
        <a target="_blank" href={link1 ?? null} className={styles.homeHeroBtn}>
          LEARN MORE
        </a>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{}}>
        <img
          // src={Img}
          src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pages/${
            image1 ?? null
          }`}
          className={styles.homeHeroImg}
          width="100%"
        />
      </Grid>
    </Grid>
  );
};

export default Hero;
