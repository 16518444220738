import React from "react";
import styles from "../../../styles/General.module.css";
import { Grid } from "@mui/material";
import CustomCarousel from "../../../components/carousel";

import Icon1 from "../../../assets/images/icon1.png";

import Icon2 from "../../../assets/images/icon2.png";

import Icon3 from "../../../assets/images/icon3.png";
import useFetch from "../../../customHooks/useFetch";

const PricingPlan = (props) => {
  const { title1, subtitle1 } = props.data;

  const { data, error, loading } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/data/plans`,
    {}
  );

  return (
    <div className={styles.homePricingPlan}>
      <h1>
        {title1}
        <span></span>
      </h1>
      <p>{subtitle1}</p>

      <div className={styles.plans}>
        {data?.map((plan, index) => (
          <div key={index}>
            <h1>{plan?.name}</h1>
            <h2>{plan?.description}</h2>
            <h3>{plan?.pricing}</h3>
            <ul>
              {plan?.listing?.split("-").map((line, i) => (
                <li key={i}>{line}</li>
              ))}
            </ul>
            <span>{plan?.note}</span>
            <a href={plan?.link} target="_blank" className={styles.planBtn}>
              {plan?.btnText}
            </a>
          </div>
        ))}
        {/* <div>
          <h1>Free</h1>
          <h2>To experience the magic!</h2>
          <h3>$0 /month</h3>

          <ul>
            <li>45 min consultation </li>
          </ul>
          <span></span>
          <button className={styles.planBtn}>ADD TO CHROME</button>
        </div> */}

        {/* <div>
          <h1>Pro</h1>
          <h2>Social Media Strategy Development Starting at: </h2>
          <h3>$1500 /month</h3>
          <ul>
            <li>Unlimited consultations</li>
          </ul>
          <span>free as 30 min consultancy intro</span>
          <button className={styles.planBtn}>GET IN TOUCH</button>
        </div> */}

        {/* <div>
          <h1>Enterprise</h1>
          <h2>STEAM INTEGRATION AND SOFTWARE DEVELOPMENT </h2>
          <h3>Custom</h3>

          <ul>
            <li>Custom integrations & automations </li>
            <li>Tailored machine learning models</li>
          </ul>

          <span></span>
          <button className={styles.planBtn}>GET IN TOUCH</button>
        </div> */}
      </div>
    </div>
  );
};

export default PricingPlan;
