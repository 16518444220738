import React from "react";
import Hero from "./sections/Hero";
import Services from "./sections/Services";
import Aboutus from "./sections/Aboutus";
import CaseStudy from "./sections/CaseStudy";
import Steam from "./sections/Steam";
import KeyBenefits from "./sections/KeyBenefits";
import PricingPlan from "./sections/PricingPlan";

import useFetch from "../../customHooks/useFetch";
import Contact from "./sections/Contact";

const Home = () => {
  const { data, error, loading } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/data/pages`,
    {}
  );

  return (
    <>
      {data && data.length > 0 && <Hero data={data[0]} />}
      <Services />
      {data && data.length > 0 && <Aboutus data={data[1]} />}
      <CaseStudy />
      {data && data.length > 0 && <Steam data={data[2]} />}
      <KeyBenefits />

      {data && data.length > 0 && <PricingPlan data={data[3]} />}

      {/* <Contact /> */}
    </>
  );
};

export default Home;
