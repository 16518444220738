import React, { useEffect, useState } from "react";
import styles from "../../../styles/General.module.css";
import CustomizedTimeline from "./CustomizedTimeline"; // Import your new timeline component
import axios from "axios"; // Make sure to have axios or your preferred method for fetching data

// const Methodology = () => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     // Replace with your API endpoint
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('YOUR_API_ENDPOINT_HERE');
//         setData(response.data); // Assuming the data is in the correct format
//       } catch (error) {
//         console.error("Error fetching data", error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div className={styles.methodology}>
//       <div className={styles.homeCaseStudy}>
//         <h1>Methodology</h1>
//       </div>
//       <CustomizedTimeline data={data} /> {/* Pass data to your timeline component */}
//     </div>
//   );
// };

// export default Methodology;


// import React from "react";
// import styles from "../../../styles/General.module.css";
// import CustomCarousel from "../../../components/carousel";
const Methodology = ({ data }) => {
  
  return (
    <div className={styles.methodology}>
      <div className={styles.homeCaseStudy}>
        <h1>
          {/* <span>2 </span> */}
          Methodology
        </h1>
      </div>
      <div className={styles.methodologyCarousel}>
        <CustomizedTimeline url={null} data={data} slidesToShow={3} card={true} />
      </div>
    </div>
  );
};

export default Methodology;
