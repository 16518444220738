import React from "react";
import styles from "../../../styles/General.module.css";
import { Grid } from "@mui/material";
import CustomCarousel from "../../../components/carousel";

import Icon1 from "../../../assets/images/icon1.png";

import Icon2 from "../../../assets/images/icon2.png";

import Icon3 from "../../../assets/images/icon3.png";

const KeyBenefits = () => {
  return (
    <div className={styles.homeKeyBenefits}>
      <h1>
        Key Benefits<span></span>
      </h1>
      {/* <p>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam.
      </p> */}

      <div className={styles.benefits}>
        <div>
          <label>5+ Countries</label> <img src={Icon1} />
        </div>
        <div>
          <label>100% Friendly Product</label> <img src={Icon2} />
        </div>
        <div>
          <label>Made Following the SGDS</label> <img src={Icon3} />
        </div>
      </div>
    </div>
  );
};

export default KeyBenefits;
