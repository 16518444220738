import React from "react";
import styles from "../../../styles/General.module.css";
import { Grid } from "@mui/material";
import Logo from "../../../assets/logo/logooo.png";
import Team from "../../../assets/images/ourTeam.png";
import Educ from "../../../assets/images/brksEducation.png";

const Aboutus = ({ data }) => {
  const {
    title1,
    subtitle1,
    title2,
    image2,
    paragraph2,
    title3,
    image3,
    paragraph3,
    title4,
    image4,
    paragraph4,
  } = data;
  return (
    <div className={styles.homeAboutus} id="about-us">
      <h1>
        {title1 ?? ""} <span></span>
      </h1>
      <p>{subtitle1}</p>

      <div className={styles.homeAboutSection}>
        <Grid container>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            spacing={0}
            container
            // direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div className={styles.welcomeBlock}>
              <h1>{title2 ?? ""}</h1>
              <img
                // src={Logo}
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pages/${
                  image2 ?? null
                }`}
              />
            </div>
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            spacing={0}
            container
            // direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div
              dangerouslySetInnerHTML={{ __html: paragraph2 ?? "" }}
              className={styles.welcomeText}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            marginTop: "max(calc(100px / 2), calc((5.8vw * 100) / 100))",
          }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            spacing={0}
            container
            order={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div
              dangerouslySetInnerHTML={{ __html: paragraph3 ?? "" }}
              className={styles.ourTeamText}
            />
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            spacing={0}
            container
            order={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div className={styles.ourTeamBlock}>
              <h1>{title3 ?? ""}</h1>
              <img
                // src={Team}
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pages/${
                  image3 ?? null
                }`}
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            marginTop: "max(calc(100px / 2), calc((5.8vw * 100) / 100))",
          }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            spacing={0}
            container
            // direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div className={styles.brksBlock}>
              <h1>{title4}</h1>
              <img
                //  src={Educ}
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pages/${
                  image4 ?? null
                }`}
              />
            </div>
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{}}
            spacing={0}
            container
            // direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <div
              dangerouslySetInnerHTML={{ __html: paragraph4 ?? "" }}
              className={styles.brksText}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Aboutus;
